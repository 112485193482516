import SharedServiceInstance from "@/services/shared-service";
var _ = require('lodash');

export const CategoryHelper = {
    getCurrentCategory: (categoryCode) => {
        let currentCategory;

        if (categoryCode) {
            const sharedService = SharedServiceInstance;
            currentCategory = _.find(sharedService.categories, item => item.code === categoryCode);
        }

        return currentCategory;
    },
    getSubCategories: (categoryCode) => {
        let subCategories = [];

        if (categoryCode) {
            const sharedService = SharedServiceInstance;
            const currentCategory = _.find(sharedService.categories, item => item.code === categoryCode);
            if (currentCategory) {
                subCategories = _.filter(sharedService.categories, item => item.parentId === currentCategory.id);
            }

        }

        return subCategories;
    }
}
